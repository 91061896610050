.schedule-tab,
.schedule-tab__new-box__selectors {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.schedule-tab {
  /* This is a hack until we re-layout this entire screen */
  height: 400px;
}

.schedule-tab__new-box {
  box-shadow: 1px 1px 3px 2px #3331;
  border-radius: 5px;
  flex: 2;
}
.schedule-tab__list-box {
  box-shadow: 1px 1px 3px 2px #3331;
  border-radius: 5px;
  flex: 1;
  display: flex;
  align-items: stretch;
}

.schedule-tab__new-box__selectors__column {
  flex: 1;
  margin-bottom: 3px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;
}

.schedule-tab__full-width-button {
  display: inline-block;
}
