.modal-close-btn {
  border: none;
  cursor: pointer;
  appearance: none;
  height: 25px;
  width: 25px;
  background-color: transparent;
  margin-right: 10px;
  margin-top: 10px;
  color: #1c1b1f;
  float: right;
  padding: 0;
  font-size: 30px;
}

.modal-close-btn:hover {
  color: #6c6b70;
}
