.weight-chart-container {
  display: flex;
  flex-direction: row;

  height: 100%;
  width: 100%;
}

.weight-stats-container {
  flex-direction: column;
  min-width: 0;
  height: 100%;
  flex: 1 0 70%;
}

.chart-top-action-container {
  position: absolute;
  top: 10px;
  right: 10px;
}
