.note-modal p,
.note-modal h2,
.note-modal h4,
.notes-list-container div,
.notes-list-container p,
.notes-list-container button,
.notes-list-container span,
.note-preview-container p,
.note-preview-container button {
  font-family: 'Proxima Nova Rg';
  font-size: 15px;
}

.note-specialty-badge {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin-right: 2px;
}
