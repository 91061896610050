.bulk-modal {
  background-color: #f6f6f6;
  border-radius: 12px;
}

.bulk-modal > section {
  padding: 15px;
}

.bulk-modal-header-image {
  width: 105px;
  height: 22px;
  margin-bottom: 12px;
  margin-top: 17px;
}

.bulk-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;
}

.bulk-title {
  color: #1a667a;
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: 700;
}

.bulk-check-all-text {
  font-size: 16px;
  font-weight: 400;
}

.member-list-container {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background-color: white;
}

.member-list-header {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.member-list-wrapper {
  width: 450px;
  height: 610px;
  min-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  padding: 0;
}

.member-list-wrapper ul {
  padding: 0;
}

.member-list {
  min-width: 300px;
  width: 100%;
  font-size: 13px;
}

/* list row */
.member-list-item {
  border-bottom: 1px solid rgb(236, 231, 231);
  margin: 0;
  padding: 0;
  width: 100%;
}

.member-list-item-avatar {
  width: 28px;
  height: 28px;
}

.member-list-item-avatar > div {
  width: 28px;
  height: 28px;
}

.member-list-item span {
  font-size: 14px;
  font-weight: 400;
  width: 100%;
}

.member-list-item > div:hover,
.member-list-item > div:active,
.member-list-item > div:focus {
  background-color: #ecf6f6;
}

/* checkbox container */
.member-list-item > div {
  gap: 10px;
  margin-left: -1px;
}

.member-list-item > div > div {
  min-width: 0;
  margin-left: -5px;
}

.bulk-reviewed-modal {
  display: flex;
  flex-direction: column;
  padding: 50px;
  gap: 20px;
}

.bulk-reviewed-modal button {
  width: 175;
  margin: 0 auto;
  font-size: 16px;
  font-weight: 400;
}

.bulk-reviewed-message {
  color: #1a667a;
  font-size: 34px;
  font-weight: 700;
}

.bulk-reviewed-message-hint {
  align-self: center;
  font-size: 16px;
  font-weight: 400;
}

.bulk-reviewed-action {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
