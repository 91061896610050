.notes-list-container {
  width: 100%;
  background-color: white;
  border-radius: 10px;
  border: 1px solid #dbdbdb;
}

.note-list-sections {
  border: 1px solid #dbdbdb;
  border-bottom: 0;
  border-top: 0;
}

.note-list-row-icon {
  width: 15px;
  height: 15px;
  background-color: red;
  border: 0;
  border-radius: 3px;
  margin-right: 3px;
}

.note-list-row-icon p {
  font-size: 1em;
}

.note-section-element-row {
  padding: 0 0 0 1em;
}

.note-section-title {
  display: flex;
  justify-content: space-between;
  padding: 0.5em 2em 0.5em 1em;
  background-color: #f2f2f2;
}

.note-section-list-row {
  border-bottom: 1px solid #dbdbdb;
}

.note-filters svg,
button {
  color: #1a667a;
}

.notes-list-container span {
  padding: 0 1em 0 0;
}

.notes-list-container div.MuiDayCalendar-header span {
  padding: 0;
}

.notes-list-container button.MuiButtonBase-root.MuiPickersDay-root {
  font-family: 'Proxima Nova Rg';
  font-size: 13px;
  height: 1.9em;
  width: 2.3em;
  margin: 5px;
}

.notes-list-container button.MuiButtonBase-root.MuiPickersDay-root:hover {
  color: white;
  background-color: #1a667a;
}

.notes-list-container button.MuiButtonBase-root.MuiPickersDay-root.Mui-selected {
  color: white;
  background-color: #1a667a;
}

.notes-list-container .MuiPickersCalendarHeader-root {
  padding: 0 0 0 15px;
}

.notes-list-container .MuiDateCalendar-root {
  width: 100%;
}

.note-filters span.MuiFormControlLabel-label {
  padding: 0 1em 0 0;
}

.notes-list-container button.MuiButtonBase-root.MuiPickersDay-root span {
  color: #404040;
  opacity: 60%;
}

.note-section-list-row.note-section-element-row button {
  font-weight: 300;
  text-transform: capitalize;
  color: #1a667a;
}
