.audio-player {
  --primary: #1a667a;
  --secondary: white;

  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.audio-player .forward-backward {
  background: none;
  border: none;
  display: flex;
  align-items: center;
  /*font-family: monospace;*/
  font-size: 1rem;
  cursor: pointer;
  color: var(--primary);
}

.audio-player .forward-backward:hover {
  color: var(--primary);
}

.audio-player .play-pause {
  background: var(--primary);
  border: none;
  /* border-radius: 50%; */
  width: 2rem;
  height: 2rem;
  font-size: 1rem;
  color: var(--secondary);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.audio-player .play-pause:hover {
  background-color: var(--primary);
}

.audio-player .play {
  position: relative;
}

.audio-player .current-time,
.audio-player .duration {
  /*font-family: monospace;*/
  font-size: 1rem;
}

.audio-player .duration {
  margin-left: 0.5rem;
  min-width: 4rem;
}

.audio-player .current-time {
  min-width: 4rem;
  margin-left: 1.5rem;
  margin-right: 0.5rem;
}

.audio-player .progress-bar-container {
  flex: 1;
}

.audio-player .progress-bar {
  --bar-bg: #ffe3d4;
  --seek-before-width: 0;
  --seek-before-color: #f37962;
  --knobby: #1a667a;
  --selectedKnobby: #1a667a;

  appearance: none;
  background: var(--bar-bg);
  border-radius: 10px;
  position: relative;
  width: 100%;
  height: 11px;
  outline: none;
}

/* progress bar - safari */
.audio-player .progress-bar::-webkit-slider-runnable-track {
  background: var(--bar-bg);
  border-radius: 10px;
  position: relative;
  width: 100%;
  height: 11px;
  outline: none;
}

/* progress bar - firefox */
.audio-player .progress-bar::-moz-range-track {
  background: var(--bar-bg);
  border-radius: 10px;
  position: relative;
  width: 100%;
  height: 11px;
  outline: none;
}

.progress-bar::-moz-focus-outer {
  border: 0;
}

/* progress bar - chrome and safari */
.audio-player .progress-bar::before {
  content: '';
  height: 11px;
  width: var(--seek-before-width);
  background-color: var(--seek-before-color);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  cursor: pointer;
}

/* progress bar - firefox */
.audio-player .progress-bar::-moz-range-progress {
  background-color: var(--seek-before-color);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  height: 11px;
}

/* knobby - chrome and safari */
.audio-player .progress-bar::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: none;
  background-color: var(--knobby);
  cursor: pointer;
  position: relative;
  margin: -2px 0 0 0;
  z-index: 3;
  box-sizing: border-box;
}

/* knobby while dragging - chrome and safari */
.audio-player .progress-bar:active::-webkit-slider-thumb {
  transform: scale(1.2);
  background: var(--selectedKnobby);
}

/* knobby - firefox */
.audio-player .progress-bar::-moz-range-thumb {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: transparent;
  background-color: var(--knobby);
  cursor: pointer;
  position: relative;
  z-index: 3;
  box-sizing: border-box;
}

/* knobby while dragging - firefox */
.audio-player .progress-bar:active::-moz-range-thumb {
  transform: scale(1.2);
  background: var(--selectedKnobby);
}

.audio-player .download-link {
  margin-left: 1rem;
}

.audio-player .download-link a {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--primary);
}

.audio-player .speed-selector {
  margin-left: 0.5rem;
  border-bottom: none;
}

.audio-player .speed-selector,
.audio-player .speed-selector .form-control {
  min-width: 80px;
}

.audio-player .speed-selector .select {
  border-bottom: none;
  text-align: center;
}

.audio-player .speed-selector .select::after {
  border-bottom: none;
}

.MuiList-root.MuiList-padding.MuiMenu-list {
  display: flex;
  flex-direction: column;
}
