.weight-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 0;
  padding: 10px;
  flex: 1 0 30%;

  border-left: 1px solid rgb(192, 192, 192);
}

.weight-bulk-grid {
  width: 100%;
}

.weight-bottom-container {
  height: 30%;
  margin-top: 15px;
  padding: 2px;
  height: auto;
  align-self: end;
}

.weight-delete-title {
  font-size: 1rem;
}
