.manage-programs-modal {
  width: 50%;
}

.manage-programs-modal > section {
  padding: 0px;
}

.manage-programs-modal .manage-programs-container {
  display: flex;
  flex-direction: column;
}

.manage-programs-tab-content {
  display: flex;
  flex-direction: row;
}

.manage-programs-tab-content > div:first-child {
  flex: 2;
}

.manage-programs-tab-content > div:last-child {
  flex: 3;
}

.manage-programs-section {
  padding: 2em;
}

.step-label-container .step-label {
  display: flex;
  flex-direction: row;
}

.delete-program-confirmation-modal-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
  gap: 1em;
}

.manage-programs-container .actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 1em;
}

.manage-programs-section > .title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.manage-programs-form-content {
  display: flex;
  flex-direction: column;
  gap: 2em;
}

.manage-programs-form-content .actions > button {
  color: #1a667a;
  border-color: #1a667a;
}

.manage-programs-modal button.primary {
  background-color: #1a667a;
  color: white;
}

.manage-programs-modal button.secondary {
  color: #1a667a;
}

.step-label-text.selected {
  border-style: solid;
  border-radius: 5px;
  border-width: 1px;
  border-color: #1a667a;
  padding: 2px;
}

.confirmation-modal {
  padding: 2em;
}
