.transcription-container {
  margin-top: 0;
  margin-bottom: 2rem;
  font-family: 'Proxima Nova Rg';
}

.transcription-container .dialog-line {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0.8rem;
  padding: 5px;
  text-transform: none;
  text-align: left;
  color: #000;
  font-size: 15px;
  font-family: 'Proxima Nova Rg';
  user-select: text;
}

.transcription-container .dialog-line.highlight {
  border: 2px solid black;
}

.transcription-container .dialog-line.highlight.playing {
  border: 2px solid #f37962;
}

.transcription-container .dialog-line .dialog-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #000;
}

.transcription-container .dialog-line .dialog-speaker {
  margin-left: 0;
  font-weight: 600;
  font-family: 'Proxima Nova Rg';
  font-size: 15px;
}

.transcription-container .dialog-line .dialog-speaker.provider {
  color: #1a667a;
}

.transcription-container .dialog-line .time {
  font-family: 'Proxima Nova Rg';
  min-width: 4rem;
  opacity: 40%;
  font-size: 15px;
}

.transcription-container .dialog-line .dialog-text {
  width: 100%;
  font-family: 'Proxima Nova Rg';
  font-size: 15px;
  line-height: 15px;
  margin-top: 0.5rem;
  padding-left: 4rem;
}
