.step-icon-container {
  color: grey;
  display: flex;
  height: 22;
  align-items: center;
  width: 25px;
  justify-content: center;
}

.step-circle {
  border-radius: 50%;
}

.step-circle.disabled {
  width: 10px;
  height: 10px;
  background-color: currentColor;
}

.step-circle.active {
  background-color: #1a667a;
  width: 15px;
  height: 15px;
}
