.bulk-message-modal {
  padding: 0;
}

.bulk-message-modal > section {
  padding-top: 0px;
}

.bulk-message-container {
  min-width: 400px;
  height: 720px;
  min-height: 300px;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 5px;
}

.bulk-message-chat-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #ecf6f6;
  padding: 10px;
  border-radius: 5px;
}

.bulk-message-chat {
  flex: 5;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin-top: 10px;
}

.bulk-message-bubble {
  background-color: white;
  border-radius: 5px;
  max-width: 320px;
  overflow: hidden;
}

.bulk-text-input-container {
  display: flex;
  height: 100px;
  border-radius: 18px;
  align-items: flex-end;
}

.bulk-message-input {
  flex: 1;
  width: 90%;
  height: 100%;
  margin: 0 auto;
  align-self: center;
  background-color: white;
  border-radius: 18px;
  border: none;
  padding: 18px 20px 18px 18px;
  font-size: 14px;
  resize: none;
}

.bulk-message-input-button,
.bulk-message-input-button-disabled {
  border-radius: 20px;
  background-color: #1a667a;
  color: white;
  border: none;
  cursor: pointer;
  appearance: none;
  height: 30px;
  width: 30px;
  position: absolute;
  right: 30px;
  margin-bottom: 10px;
}

.bulk-message-input-button-disabled {
  background-color: #cfdadc;
  cursor: default;
  color: white;
}

.bulk-message-input-button-icon {
  height: 10px;
  width: 13px;
  color: white;
  fill: white;
}

.bulk-message-template {
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  font-size: 14px;
  gap: 5px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  margin-top: 5px;
}

.bulk-message-template-title-container {
  display: flex;
  gap: 5px;
  align-items: center;
}

.bulk-message-pill-container {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.bulk-message-template-pill {
  background-color: #a8d9d6;
  color: #1a667a;
  padding: 7px;
  border-radius: 5px;
  cursor: pointer;
}
