.MuiDataGrid-columnHeaderTitle {
  font-weight: 600 !important;
}

.MuiBadge-root > .MuiBadge-badge {
  border: white 1px solid;
}

.food > .MuiBadge-root > .MuiBadge-badge {
  background-color: #039292;
}
.food-comments > .MuiBadge-root > .MuiBadge-badge {
  background-color: #8b0505;
}
.card-comments > .MuiBadge-root > .MuiBadge-badge {
  background-color: #520d97;
}

.member-chat > .MuiBadge-root > .MuiBadge-badge {
  background-color: #944a00;
}

.weights > .MuiBadge-root > .MuiBadge-badge {
  background-color: #0e008b;
}

.vitals > .MuiBadge-root > .MuiBadge-badge {
  background-color: #098b1f;
}

.MuiBadge-badge.MuiBadge-colorInfo {
  background-color: #8e8e8e;
}

.panel-sidebar {
  gap: 10;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
