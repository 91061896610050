.schedulePreviewCard-container {
  display: flex;
  flex-direction: column;
  padding: 1.5em;
  min-width: calc(30vw);
  max-width: 540px;
}

.schedulePreviewCard-container button {
  width: 100px;
}

.schedulePreviewCard-container div {
  display: flex;
}

.schedulePreviewCard-container > div {
  flex-direction: row;
  justify-content: space-between;
  gap: 1em;
}
.schedulePreviewCard-container > hr {
  border: none;
  height: 0.5px;
  background-color: #c1c1c1;
  margin-top: 20px;
  margin-bottom: 20px;
}

.schedulePreviewCard-container .appointment-title {
  font-size: 1.2em;
  font-weight: 600;
  margin-bottom: 20px;
}

.schedulePreviewCard-container .info {
  flex-direction: column;
}

.schedulePreviewCard-container .link {
  align-items: flex-start;
  white-space: nowrap;
}

.schedulePreviewCard-container hr {
  width: 100%;
}

.schedulePreviewCard-container .info-with-icon {
  margin-top: 2px;
  align-items: center;
  gap: 8px;
}

.schedulePreviewCard-container .appointment-info .appointment-type,
.schedulePreviewCard-container .notes-info .title,
.schedulePreviewCard-container .member-info .member-name {
  font-weight: 600;
}

.schedulePreviewCard-container .member-info .info {
  flex-grow: 1;
}
