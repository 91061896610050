.active-sessions__root {
  background-color: white;
  border: 0.5px solid #dddddd;
  border-radius: 5px;
  margin-top: 30px;
  padding-bottom: 10px;
}

.active-sessions__tabs-right {
  .MuiTabs-flexContainer {
    justify-content: flex-end;
  }
}

.active-sessions__schedule-appointment-card {
  border-radius: 8px;
  border: 1px solid #dddddd;
  margin: 8px;
  padding: 8px;
}

.active-sessions__schedule-appointment-card .date {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-right: 0.5px solid gray;
}
