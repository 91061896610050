.note-preview-container {
  width: 70vw;
  height: 80vh;
  background-color: white;
  border-radius: 5px;
}

.note-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.note-icon-disabled {
  color: #a6a6a6;
}

.note-modal p,
.note-modal li,
.note-modal div {
  font-size: 15px;
}

.note-edition-data-review-table .custom-panel-table p,
.note-edition-data-review-table .custom-panel-table div,
.note-edition-data-review-table .custom-panel-table li {
  font-size: 0.875rem;
}
