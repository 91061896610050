.compact-filter-select {
  height: 26px;
  width: 180px;
  min-width: 50px;
}

.compact-filter-select div {
  padding: 0;
}

.compact-filter-select input {
  align-self: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Proxima Nova Rg';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  margin-left: 5px;
  height: 15px;
  width: 100%;
  padding: 10px;
}

.compact-filter-select input + div > button:nth-child(1) {
  margin: 0;
  font-weight: 100;

  padding: 0;
}

.render-option-li {
  font-style: normal;
  font-family: 'Proxima Nova Rg';
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
}
