.note-edition-content {
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  padding-left: 1.4rem;
  padding-right: 1.4rem;
  width: 40vw;
  height: calc(100vh - 4rem);
  overflow-y: auto;
}

.note-edition-content .edit-section {
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #e4e4e4;
}

.note-edition-column-wrapper {
  height: calc(100vh - 5rem);
}

.note-edition-content-header {
  align-items: center;
  border-bottom: 1px solid #e4e4e4;
  display: flex;
  justify-content: space-between;
  padding: 0rem 1.3rem 0.2rem 1.3rem;
}

.note-edition-preview {
  width: 40vw;
}

.note-edition-accordion.MuiAccordion-root {
  box-shadow: none;
}

.note-edition-accordion.MuiAccordion-root > div {
  border-bottom: 1px solid #e4e4e4;
  padding: 0px;
  flex-direction: row-reverse;
}

.note-edition-accordion.MuiAccordion-root > div .MuiAccordionSummary-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 5px;
}

.note-edition-modal-container {
  display: flex;
  flex-direction: column;
  justify-content: right;
  align-items: flex-end;
  width: 100vw;
  height: 100vh;
}

.note-edition-modal-container .note-edition-container {
  width: 80vw;
  height: calc(100% - 1rem);
  background-color: white;
  border-radius: 5px;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  padding-bottom: 3em;
}

.note-edition-container .note-edition-content-container {
  display: flex;
  flex-direction: row;
  background-color: white;
  border-radius: 5px;
  margin-top: 3em;
  padding-bottom: 3em;
  width: 100vw;
}

.note-edition-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5em 1.5em 0.5em 1.5em;
  border-bottom: 1px solid #dbdbdb;
  background-color: white;
  position: fixed;
  height: 2.5em;
  width: 80vw;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  z-index: 1;
}

.medical-problem-section-button[type='button'] {
  font-family: 'Proxima Nova Rg';
  text-transform: capitalize;
  font-weight: 500;
  font-size: 1em;
}

.medical-problem-section-button.cancel-problem-button {
  padding: 0.5em 1em;
  color: #1a667a;
  margin-right: 1em;
}

.medical-problem-section-button.remove-problem-button {
  padding: 0.5em 1em;
  background-color: #1a667a;
  color: #fff;
}

.medical-problem-section-button.delete-problem-button {
  color: #1a667a;
  width: 30%;
  height: 2.5em;
  padding: 0px 15px 0px 5px;
  justify-content: end;
}

.note-preview-duration {
  margin-bottom: 2em;
}

.note-edition-data-review-table > div > div {
  height: fit-content;
}

.note-edition-add-problem .selector {
  margin-top: 16px;
  padding-top: 1rem;
}

.diagnosis-section-item {
  margin-top: 1rem;
}
