:root {
  --color-NS: #30ffc1;
  --color-ES: #7bdbff;
  --color-MD: #ff9083;
  --color-MS: #ffe24c;
  --color-DIA: #adadad;
}
.calendar-box {
  display: flex;
  flex-direction: column;
  margin: 40px 20px;
  padding: 0px 20px;
}

.calendar-header p {
  font-size: 20px;
  font-weight: 400;
}

.calendar-filters {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 1em 0px;
}

.calendar-filters input {
  font-size: 1em;
}

.calendar-filters label {
  font-size: 1em;
}

.calendar-event.fc-timegrid-event {
  background-color: white;
  border-color: white;
  border-radius: 4px;
  box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.2), 0px -1px 3px -1px rgba(0, 0, 0, 0.1);
}

.calendar-event .fc-event-main {
  color: black;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.calendar-view {
  background-color: #f9f9f9;
}

.calendar-event .event-content {
  background-color: white;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.event-content.selected {
  color: white;
  background-color: gray; /* Placeholder color */
}
.event-content.selected.specialty-NS {
  background-color: var(--color-NS);
}

.calendar-event .event-content::before {
  content: ' ';
  display: inline-block;
  min-width: 4px;
  border-radius: 5px;
  margin: 2px;
  background-color: gray; /* Placeholder color */
}

.event-content.specialty-NS::before {
  background-color: var(--color-NS);
}

.calendar-event .event-content {
  display: flex;
  flex-direction: row;
  gap: 5px;
  overflow: hidden;
  font-size: 12px;
}

.calendar-event .event-member {
  font-weight: 600;
}
