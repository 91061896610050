.custom-panel-table {
  border-radius: 10px !important;
}

.custom-panel-table.MuiDataGrid-columnHeaderTitle {
  color: #404040;
}

.custom-panel-table.MuiDataGrid-columnHeaderTitleContainer {
  justify-content: space-between;
}

.custom-panel-table.MuiDataGrid-virtualScroller {
  background-color: #f4f4f4;
  color: #404040;
}

.custom-panel-table.MuiDataGrid-row {
  min-height: 35px !important;
  max-height: 60px !important;
}

.custom-panel-table.MuiDataGrid-cell {
  color: #404040;
  font-size: 16px;
  font-weight: 500;
  min-height: 35px !important;
  max-height: 60px !important;
}

.custom-panel-table > div > div > div {
  height: 5em;
}
