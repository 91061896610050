.note-edition-preview-container .action-button {
  font-family: 'Proxima Nova Rg';
  color: #1a667a;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 1rem;
  padding: 0;
  margin-left: 1rem;
}

.note-edition-column-wrapper .note-edition-preview-container {
  height: calc(100vh - 5rem);
}

.note-edition-column-wrapper .note-edition-content {
  height: 100%;
  max-height: calc(100% - 1rem);
  padding-bottom: 2rem;
}
