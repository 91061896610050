/* Hack to allow the avatar to overflow bellow the message */
.cs-message__avatar {
  min-width: 42px; /* ...so if the md floats, then this container does not shrink */
}
.cs-avatar--md {
  position: absolute; /* So the content of the avatar floats over the message, without forcing additional space! */
}

.provider__ns .cs-message__content {
  background-color: rgb(231, 124, 124) !important;
}
.provider__md .cs-message__content {
  background-color: rgb(111, 111, 206) !important;
}
.provider__es .cs-message__content {
  background-color: rgb(172, 216, 126) !important;
}
.provider__dia .cs-message__content {
  background-color: rgb(190, 124, 208) !important;
}
