.note-preview-modal-container {
  display: flex;
  flex-direction: column;
  justify-content: right;
  align-items: flex-end;
  width: 100vw;
  height: 100vh;
}

.note-preview-modal-container .note-preview-container {
  width: 80vw;
  height: calc(100% - 1rem);
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 5px;
  margin-top: auto;
  margin-bottom: auto;
}

.note-preview-content-container {
  display: flex;
  flex-direction: column;
  width: 80vw;
  margin-top: 1rem;
  padding-left: 1.3rem;
  padding-right: 1.3rem;
  overflow-y: scroll;
  overflow-x: auto;
}

.note-preview-content-container.transcript {
  margin-top: 0;
}

.note-tab-selector button.Mui-selected {
  color: #1a667a;
}

.note-tab-selector .MuiTabs-indicator {
  background-color: #1a667a;
}

.note-tab-selector button {
  text-transform: none;
}

.note-preview-content {
  color: #818181;
}

.note-preview-content-header {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 1.3rem 0 1.3rem;
}

.note-preview-media-content-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  margin-top: 1rem;
  padding: 0.5rem 1.3rem 0.5rem 1.3rem;
}

.note-preview-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: 1px solid #dbdbdb;
  background-color: white;
  width: 80vw;
}

.note-preview-header .appointment-details {
  border-bottom: 1px solid #dbdbdb;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1.3rem 0rem 1.3rem;
}

.note-preview-header-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

button.note-preview-action-buttons {
  color: #1a667a;
  text-transform: capitalize;
  font-weight: 500;
}

.note-preview-header-buttons button.note-preview-action-buttons {
  margin-left: 0.2rem;
}

.preview-sign-note-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80vw;
  height: 100vh;
  margin-left: 20vw;
}

.preview-sign-note-modal .note-sign-modal {
  width: 28rem;
  height: 13rem;
  background-color: white;
  border-radius: 5px;
}

.preview-sign-note-modal button.note-sign-button,
.preview-sign-note-modal button.note-sign-button:hover {
  width: 8rem;
  height: 3rem;
  text-transform: capitalize;
  background-color: white;
  border-color: white;
  color: #1a667a;
}

.preview-sign-note-modal button.note-sign-button.confirm,
.preview-sign-note-modal button.note-sign-button.confirm:hover {
  background-color: #1a667a;
  border-color: #1a667a;
  color: white;
}
